<template>
  <VueMultiselectSmall
  
    :modelValue="value"
    @update:modelValue="
      updateModel($event);
    "
    :options="opt"
    :searchable="searchable"
    :close-on-select="true"
    :allow-empty="true"
    :multiple="multiple"
    :mode="mode"
    @search-change="getOptions"
    :disabled="disabled"
    :placeholder="placeholder"
    track-by="id"
    :name="name"
    :custom-name="getName"
    :id="id"
  >
  </VueMultiselectSmall>
</template>

<script setup>
import { ref, onMounted, computed } from "vue";
import VueMultiselectSmall from "@/components/global/select/MultiselectSmall.vue";
import axios from "axios";
import BTooltip from "@/components/global/BTooltip.vue";

const emits = defineEmits(["update:value", "select"]);

function getOptions(query) {
  if (query.length > 2) {
    clearTimeout(timeout.value);
    // Starte nach 500ms Verzögerung nach dem letzten Tastendruck
    timeout.value = setTimeout(function () {
      // if ( !this.multiple ) vm.$emit('update:modelValue', '' )
      axios
        .post(props.searchurl, {
          suche: query,
          main: props.autoattrib,
          filters: props.filters,
        })
        .then((response) => {
          if (response.data.data.length !== 0) props.source = response.data.data; // else vm.source = vm.modelValue;
        });
    }, 500);
  } else {
    //   vm.source = vm.modelValue
  }
}

function getName({ name }) {
  return `${name}`;
}

function updateModel(e) {
  console.log("updateModel:" + e.id);
  emits("update:value", e);
  emits("select", e);
}

const source = ref([]);
const timeout = ref(null);

const opt = computed(() => {
  console.log;
  return props.searchurl && props.source.length !== 0 ? source.value : props.options;
});

onMounted(() => {
  if (props.options.length == 1) {
    console.log("1 Option");
    //  this.modelValue = this.options;
  }

  let findOption = props.options.find((option) => option.id === props.value.id);
});

const props = defineProps({
  options: {
    type: [String, Array, Object],
    default: [],
  },
  value: {
    type: [String, Array, Object],
    default: [],
  },
  mode: {
    type: String,
    default: "list",
  },
  name: {
    type: String,
    default: "",
  },
  placeholder: {
    type: String,
    default: "Bitte auswählen",
  },
  id: {
    type: String,
    default: "",
  },
  hinweise: {
    type: [String, Array],
    default: "",
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  tooltip: {
    type: String,
    default: "",
  },
  searchable: {
    type: Boolean,
    default: false,
  },
  searchurl: {
    type: String,
    default: "",
  },
  multiple: {
    type: Boolean,
    default: false,
  },
  filters: {
    type: [String, Array],
    default: "",
  },
  autoattrib: {
    type: String,
    default: "name",
  },
  special: {
    type: Boolean,
    default: false,
  },
  tooltip: {
    type: Object,
    default: {
      title: "",
      text: "",
    },
  },
  exact: {
    type: Boolean,
    default: false,
  },
});
</script>

<style lang="scss"></style>
