<template>
  <transition name="modal-fade">
    <div class="fixed inset-0 z-10000 overflow-y-auto backdrop-blur-sm">
      <div
        class="flex items-center justify-center min-h-screen px-4 pt-4 pb-4 text-center sm:block sm:p-0"
      >
        <div class="fixed inset-0 transition-opacity" aria-hidden="true">
          <div class="absolute inset-0 bg-gray-500 opacity-0"></div>
        </div>

        <!-- This element is to trick the browser into centering the modal contents. -->
        <span
          class="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
          >&#8203;</span
        >
        <div
          class="w-400 inline-block overflow-hidden text-left align-bottom transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:align-middle sm:max-w-lg sm:w-full min-h-9/10"
          :class="{ 'pb-50': true }"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div class="pb-4 bg-white">
            <div class="sm:flex sm:items-start">
              <button
                type="button"
                @click="close()"
                class="absolute right-3 top-2 h-10 inline-flex justify-center items-center p-2 rounded-full text-base font-medium text-white shadow-sm hover:bg-drc-orange sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
              >
                <svg-x class="w-8 h-8" />
              </button>
              <div
                class="flex items-center justify-between text-white bg-drc-gruen-100 w-full rounded-t-lg h-14 px-10 uppercase"
              >
                BUGTRACKER: {{ bugtracker.name }}
              </div>
              <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                <b-bugtrack
                  v-for="(bugtrack, index) in bugtracker.bugtracks"
                  :bugtrack="bugtrack"
                  :page_id = "bugtracker.id"
                  @delete="deleteBugtrack(index)"
                />
                <br />
                <b-trenner />
                <div class="flex flex-col justify-start mx-5 desktop:mx-10  py-5">
                  <b-textarea-autoresize
                    class="h-24 w-full px-3 py-2 text-gray-700 border rounded min-w-75 text-sm border-drc-grau-400 focus:border-none focus:bg-white focus:ring focus:ring-drc-gelb"
                    :class="{ 'bg-drc-grau-700 text-black': disabled }"
                    placeholder="Neue Nachricht eingeben"
                    id="newnotice"
                    name="newnotice"
                    :disabled="disabled"
                    :value="newBugtrackText"
                    @update:value="newBugtrackText = $event"
                    min-height="3"
                    max-height="1000"
                  ></b-textarea-autoresize>
                  <div class="flex flex-col justify-start">
                    <input
                      class="w-full mt-2 h-8 px-3 py-2 text-gray-700 border text-sm rounded min-w-75 border-drc-grau-400 focus:border-none focus:bg-white focus:ring focus:ring-drc-gelb"
                      :class="{
                        'border-2 !border-drc-gruen-300 bg-drc-gruen-600 bg-opacity-10': special,
                        'bg-drc-grau-500 text-black': disabled,
                      }"
                      type="text"
                      placeholder="URL"
                      v-model="newBugtrackUrl"
                    />
                  </div>
                </div>

                <div class="px-10 py-3 bg-gray-50 flex justify-start">
                  <button
                    type="button"
                    @click="save()"
                    class="text-white inline-flex justify-center w-48 px-4 py-2 mt-1 bg-drc-gruen-300 font-medium text-black border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                  >
                    Speichern
                  </button>
                  <button
                    type="button"
                    @click="close()"
                    class="ml-5 inline-flex justify-center w-48 px-4 py-2 mt-1 text-base font-medium bg-drc-marker-rot text-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                  >
                    Verwerfen
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script setup>
import { ref, watchEffect } from "vue";
import _ from "lodash";
import axios from "axios";
import BTrenner from "@/components/global/BTrenner.vue";
import BTextareaAutoresize from "@/components/global/inputs/BTextareaAutoresize.vue";
import BBugtrack from "@/components/global/BBugtrack.vue";
import { useGlobalStore } from "@/stores/global";
import { storeToRefs } from "pinia";

const globalStore = useGlobalStore();
const { bugtracker } = storeToRefs(globalStore);

const props = defineProps(["open"]);
const emit = defineEmits(["close"]);

const { open } = props;

function cancel() {
  bugtracker.value.modal = false;
  emit("close");
}

const newBugtrackText = ref("");
const newBugtrackUrl = ref("");
const disabled = ref(false);

function save(e) {
  const payload = {
    text: newBugtrackText.value,
    url: newBugtrackUrl.value,
    closed: 0,
    page_id: bugtracker.value.id,
    page_name: bugtracker.value.name,
  };

  axios.post("api/bugtrack", payload).then((response) => {
    console.log(response.data);
    if (response.data.bugtrack.id) {
      bugtracker.value.bugtracks.push({
        text: response.data.bugtrack.text,
        author: response.data.bugtrack.author,
        photo: response.data.bugtrack.photo,
        closed: response.data.closed,
        user_id: response.data.bugtrack.user_id,
        created_at: response.data.bugtrack.created_at,
        updated_at: response.data.bugtrack.updated_at,
        id: response.data.bugtrack.id,
        url: response.data.bugtrack.url,
      });
      newBugtrackText.value = "";
      newBugtrackUrl.value = "";
    }
  });
}

function deleteBugtrack(i) {
  bugtracker.value.bugtracks.splice(i, 1);

  // const payload = {
  //    text: newBugtrack.value,
  //    id: hund.id,
  // }
}

function close() {
  bugtracker.value.modal = false;
}
</script>

<style scoped lang="scss"></style>
