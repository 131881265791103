<template>
  <div class="relative w-full  max-w-250 min-w-75" v-click-outside="closeMultiselect">
    <template v-for="(option, index) of visibleValues" @mousedown.prevent>
      <slot
        v-if="mode == 'list'"
        name="tag"
        :option="option"
        :search="search"
        :remove="removeElement"
      >
        <span class="relative flex mb-1" :key="index">
          <span
            class="relative flex items-center w-full h-8 pl-3 truncate bg-white whitespace-nowrap pr-7 "
            
            v-text="getOptionName(option)"
          ></span>


        </span>
      </slot>
    </template>
    <!-- v-if="!(multiple && disabled) && (options.length >= internalValue.length)" -->
    <div
      v-if="options.length >= internalValue.length"
      :tabindex="searchable ? -1 : tabindex"
      :class="{
        'multiselect--active': isOpen,
        'multiselect--disabled': disabled,
        'multiselect--above': isAbove,
      }"
      @focus="activate()"
      @blur="searchable ? false : deactivate()"
      @keydown.self.down.prevent="pointerForward()"
      @keydown.self.up.prevent="pointerBackward()"
      @keypress.enter.tab.stop.self="addPointerElement($event)"
      @keyup.esc="deactivate()"
      class="relative w-full h-8 max-w-250 min-w-75"
      role="combobox"
      :aria-owns="'listbox-' + id"
    >

      <slot name="clear" :search="search"></slot>
      <div ref="tags" class="">
        <slot
          name="selection"
          :search="search"
          :remove="removeElement"
          :values="visibleValues"
          :is-open="isOpen"
        >
          <!-- BOX mit den Tagelementen multiselect__tag -->
          <div
            class="flex flex-wrap items-center justify-start w-full pr-10 border rounded-l"
            v-if="visibleValues.length > 0 && (mode == 'normal' || mode == 'tags')"
          >
            <template v-for="(option, index) of visibleValues" @mousedown.prevent>
              <slot name="tag" :option="option" :search="search" :remove="removeElement">
                <span
                  class="relative flex items-center h-8 pl-2 m-1 mr-1 truncate bg-white rounded-full pr-7  max-w-50"
                  :key="index"
                >
                  <span v-text="getOptionName(option)"></span>
                  <span
                    tabindex="1"
                    @keypress.enter.prevent="removeElement(option)"
                    @mousedown.prevent="removeElement(option)"
                  >
                    <svg-x class="w-6 h-6" />
                  
                  </span>
                </span>
              </slot>
            </template>
          </div>
          <template v-if="internalValue && internalValue.length > limit">
            <slot name="limit">
              <strong
                class="multiselect__strong"
                v-text="limitText(internalValue.length - limit)"
              />
            </slot>
          </template>
        </slot>
        <transition name="multiselect__loading">
          <slot name="loading">
            <div v-if="loading" class="multiselect__spinner" />
          </slot>
        </transition>
        <input
          ref="search"
          v-if="searchable"
          :name="name"
          :id="id"
          type="text"
          autocomplete="off"
          spellcheck="false"
          :placeholder="placeholder_search"
          :style="inputStyle"
          :value="search"
          :disabled="disabled"
          :tabindex="tabindex"
          @input="updateSearch($event.target.value)"
          @focus.prevent="activate()"
          @blur.prevent="deactivate()"
          @keyup.esc="deactivate()"
          @keydown.down.prevent="pointerForward()"
          @keydown.up.prevent="pointerBackward()"
          @keypress.enter.prevent.stop.self="addPointerElement($event)"
          @keydown.delete.stop="removeLastElement()"
          class="w-full h-8 px-2 text-left text-gray-700 bg-white rounded focus:border-l focus:border-drc-gruen-300 focus:border-t focus:rounded-b-none"
          :class="{
            '!bg-drc-grau-500 text-black': disabled,
          }"
          :aria-controls="'listbox-' + id"
        />
        <!-- multiselect__input -->

        <span
          v-if="isSingleNameVisible"
          class="flex items-center w-full py-1 px-2 justify-end text-gray-700  focus:border-drc-gruen-300 "
          :class="{
            '!border-b-0 !rounded-b-none !border-drc-gruen-300': isOpen,
            'bg-drc-grau-500 !text-black': disabled,
            'border-2 border-drc-gruen-300 bg-drc-orange bg-opacity-50': special,
          }"
          @mousedown.prevent="toggle()"
        >
          <slot name="singleName" :option="singleValue">
            <span class="text-drc-grau-300 font-bold p-1 px-2 rounded">{{ currentOptionName }}</span>
          </slot>
        </span>
        <span
          v-if="
            isPlaceholderVisible ||
            (multiple && !searchable) ||
            (multiple && searchable && !isOpen)
          "
          class="flex items-center h-8 pl-3 pr-20 text-left text-gray-700 bg-white border rounded border-drc-grau-400 focus:border-drc-gruen-300"
          :class="{
            '!border-b-0 !rounded-b-none !border-drc-gruen-300': isOpen,
            '!bg-drc-grau-500 !text-black !bg-opacity-100': disabled,
            'border-2 border-drc-gruen-300 bg-drc-orange bg-opacity-50': special,
          }"
          @mousedown.prevent="toggle()"
        >
          <slot name="placeholder">
            {{ placeholder }}
          </slot>
        </span>
      </div>

      <!-- AUSWAHLBOX -->
      <transition name="multiselect">
        <!-- multiselect__content-wrapper / Options-Box-->
        <div
          class="overflow-y-auto border border-t-0 rounded-b bg-drc-grau-600 border-drc-gruen-400"
          :class="{ 'border-2 border-drc-gruen-300': special }"
          v-if="isOpen"
          @focus="activate()"
          @blur="deactivate()"
          tabindex="-1"
          @mousedown.prevent
          :style="{ maxHeight: optimizedHeight + 'px' }"
          ref="list"
        >
          <!-- multiselect__content / komplette Options-Zeile -->
          <ul class="w-full" :style="contentStyle" role="listbox" :id="'listbox-' + id">
            <slot name="beforeList"></slot>
            <li v-if="multiple && max === internalValue.length">
              <!-- multiselect__option / Option -->
              <span class="">
                <slot name="maxElements"
                  >Maximal {{ max }} Elemente auswählbar. Bitte entfernen Sie ein Element,
                  um eine anderes Element hinzufügen zu können.</slot
                >
              </span>
            </li>
            <template v-if="!max || internalValue.length < max">
              <!-- multiselect__element / Option -->
              <li
                class=""
                v-for="(option, index) of filteredOptions"
                :key="index"
                v-bind:id="id + '-' + index"
                v-bind:role="
                  !(option && (option.$isName || option.$isDisabled)) ? 'option' : null
                "
              >
                <div
                  v-if="!(option && (option.$isName || option.$isDisabled))"
                  @click.stop="select(option)"
                  @mouseenter.self="pointerSet(index)"
                  :data-select="option && option.isTag ? tagPlaceholder : selectNameText"
                  :data-selected="selectedNameText"
                  :data-deselect="deselectNameText"
                  class="flex items-center justify-between w-full h-8 px-3"
                  :class="{
                    'bg-drc-grau-400': optionStatus(index, option) == 'hover',
                    'bg-drc-gruen-300 text-white bold':
                      optionStatus(index, option) == 'selected',
                    'bg-drc-gelb': optionStatus(index, option) == 'hoverselected',
                  }"
                >
                  <!-- multiselect__option / Option -->
                  <slot name="option" :option="option" :search="search" :index="index">
                    <span class="">{{ getOptionName(option) }}</span>
                  </slot>
                  <span v-if="optionStatus(index, option) == 'hoverselected'">{{
                    deselectNameText
                  }}</span>
                  <span v-if="optionStatus(index, option) == 'hover'">{{
                    selectNameText
                  }}</span>
                  <span v-if="optionStatus(index, option) == 'selected'">{{
                    selectedNameText
                  }}</span>
                </div>
                <span
                  v-if="option && (option.$isName || option.$isDisabled)"
                  :data-select="groupSelect && selectGroupNameText"
                  :data-deselect="groupSelect && deselectGroupNameText"
                  :class="groupHighlight(index, option)"
                  @mouseenter.self="groupSelect && pointerSet(index)"
                  @mousedown.prevent="selectGroup(option)"
                  class="multiselect__option"
                >
                  <!-- multiselect__option / Gruppe Option -->
                  <slot name="option" :option="option" :search="search" :index="index">
                    <span>{{ getOptionName(option) }}</span>
                  </slot>
                </span>
              </li>
            </template>
            <li
              v-if="showNoResults && filteredOptions.length === 0 && search && !loading"
            >
              <!-- multiselect__option / Option -->
              <span class="multiselect__option">
                <slot name="noResult" :search="search"
                  >Keine Übereinstimmung gefunden.</slot
                >
              </span>
            </li>
            <li v-if="showNoOptions && options.length === 0 && !search && !loading">
              <!-- multiselect__option / Option -->
              <span class="multiselect__option">
                <slot name="noOptions">Die Ergebnisliste ist leer.</slot>
              </span>
            </li>
            <slot name="afterList"></slot>
          </ul>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import multiselectMixin from "./multiselectMixin.js";
import pointerMixin from "./pointerMixin.js";

export default {
  mixins: [multiselectMixin, pointerMixin],
  props: {
    /**
     * name attribute to match optional name element
     * @default ''
     * @type {String}
     */
    name: {
      type: String,
      default: "",
    },
    /**
     * mode, if multiple: tags, list, normal
     * @default ''
     * @type {String}
     */
    mode: {
      type: String,
      default: "normal",
    },
    /**
     * Presets the selected options value.
     * @type {Object||Array||String||Integer}
     */
    modelValue: {
      type: null,
      default() {
        return [];
      },
    },
    /**
     * String to show when pointing to an option
     * @default 'Press enter to select'
     * @type {String}
     */
    selectName: {
      type: String,
      default: "Auswählen",
    },
    /**
     * String to show when pointing to an option
     * @default 'Press enter to select'
     * @type {String}
     */
    selectGroupName: {
      type: String,
      default: "Gruppe auswählen",
    },
    /**
     * String to show next to selected option
     * @default 'Selected'
     * @type {String}
     */
    selectedName: {
      type: String,
      default: "Ausgewählt",
    },
    /**
     * String to show when pointing to an already selected option
     * @default 'Press enter to remove'
     * @type {String}
     */
    deselectName: {
      type: String,
      default: "Entfernen",
    },
    /**
     * String to show when pointing to an already selected option
     * @default 'Press enter to remove'
     * @type {String}
     */
    deselectGroupName: {
      type: String,
      default: "Gruppe entfernen",
    },
    /**
     * Decide whether to show pointer names
     * @default true
     * @type {Boolean}
     */
    showNames: {
      type: Boolean,
      default: true,
    },
    /**
     * Limit the display of selected options. The rest will be hidden within the limitText string.
     * @default 99999
     * @type {Integer}
     */
    limit: {
      type: Number,
      default: 9999,
    },
    /**
     * Sets maxHeight style value of the dropdown
     * @default 300
     * @type {Integer}
     */
    maxHeight: {
      type: Number,
      default: 500,
    },
    /**
     * Function that process the message shown when selected
     * elements pass the defined limit.
     * @default 'and * more'
     * @param {Int} count Number of elements more than limit
     * @type {Function}
     */
    limitText: {
      type: Function,
      default: (count) => `und ${count} mehr`,
    },
    /**
     * Set true to trigger the loading spinner.
     * @default False
     * @type {Boolean}
     */
    loading: {
      type: Boolean,
      default: false,
    },
    /**
     * Disables the multiselect if true.
     * @default false
     * @type {Boolean}
     */
    disabled: {
      type: Boolean,
      default: false,
    },
    exact: {
      type: Boolean,
      default: false,
    },
    /**
     * Fixed opening direction
     * @default ''
     * @type {String}
     */
    openDirection: {
      type: String,
      default: "",
    },
    /**
     * Shows slot with message about empty options
     * @default true
     * @type {Boolean}
     */
    showNoOptions: {
      type: Boolean,
      default: true,
    },
    showNoResults: {
      type: Boolean,
      default: true,
    },
    special: {
      type: Boolean,
      default: false,
    },
    tabindex: {
      type: Number,
      default: 0,
    },
  },
  methods: {
    /**
     * Returns the name of the option
     * @param {Object} option
     * @returns {String}
     */
   closeMultiselect() {
      if (this.isOpen) console.log("closeMultiselect");
      if(this.isOpen) this.deactivate()

}
  },
  computed: {
    isSingleNameVisible() {
      return (
        (this.singleValue || this.singleValue === 0) &&
        (!this.isOpen || !this.searchable) &&
        !this.visibleValues.length
      );
    },
    isPlaceholderVisible() {
      return !this.internalValue.length && (!this.searchable || !this.isOpen);
    },
    visibleValues() {
      if (this.multiple) {
        //   let ivl = this.internalValue.filter((iv) => {
        //     return true;
        //   });

        //   console.log(this.internalValue);
        //   console.log(this.filteredOptions);

        return this.internalValue.slice(0, this.limit);
      } else {
        return [];
      }
      // let filteredValues = this.modelValue.filter((mv) => {
      //   return this.internalValue.filter((iv) => {
      //     return mv.id == isVNode.id;
      //   });
      // });

      // return this.multiple ? this.internalValue.slice(0, this.limit) : [];
    },
    singleValue() {
      return this.internalValue[0];
    },
    deselectNameText() {
      return this.showNames ? this.deselectName : "";
    },
    deselectGroupNameText() {
      return this.showNames ? this.deselectGroupName : "";
    },
    selectNameText() {
      return this.showNames ? this.selectName : "";
    },
    selectGroupNameText() {
      return this.showNames ? this.selectGroupName : "";
    },
    selectedNameText() {
      return this.showNames ? this.selectedName : "";
    },
    inputStyle() {
      if (
        this.searchable ||
        (this.multiple && this.modelValue && this.modelValue.length)
      ) {
        // Hide input by setting the width to 0 allowing it to receive focus
        return this.isOpen
          ? { width: "100%" }
          : { width: "0", position: "absolute", padding: "0" };
      }
      return "";
    },
    contentStyle() {
      return this.options.length ? { display: "inline-block" } : { display: "block" };
    },
    isAbove() {
      if (this.openDirection === "above" || this.openDirection === "top") {
        return true;
      } else if (this.openDirection === "below" || this.openDirection === "bottom") {
        return false;
      } else {
        return this.preferredOpenDirection === "above";
      }
    },
    showSearchInput() {
      return (
        this.searchable &&
        (this.hasSingleSelectedSlot &&
        (this.visibleSingleValue || this.visibleSingleValue === 0)
          ? this.isOpen
          : true)
      );
    },
  },
};
</script>

<style>
.multiselect,
.multiselect__input,
.multiselect__single {
  font-family: inherit;
  font-size: 16px;
  touch-action: manipulation;
}

.multiselect {
  box-sizing: content-box;
  display: block;
  position: relative;
  width: 100%;

  text-align: left;
  color: #35495e;
  background: #ff0;
}

.multiselect * {
  box-sizing: border-box;
}

.multiselect:focus {
  outline: none;
}

.multiselect--disabled {
  background: #d8d8d8;
  color: #000;
  pointer-events: none;
  opacity: 1;
}

.multiselect--active {
 z-index: 1;
}

.multiselect--active:not(.multiselect--above) .multiselect__current,
.multiselect--active:not(.multiselect--above) .multiselect__input,
.multiselect--active:not(.multiselect--above) .multiselect__tags {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.multiselect--active .multiselect__select {
  transform: rotateZ(180deg);
}

.multiselect--above.multiselect--active .multiselect__current,
.multiselect--above.multiselect--active .multiselect__input,
.multiselect--above.multiselect--active .multiselect__tags {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.multiselect__input,
.multiselect__single {
  position: relative;
  display: inline-block;
  min-height: 16px;
  line-height: 16px;
  border: none;
  border-radius: 5px;
  background: #e0e;
  padding: 0 0 0 5px;
  width: calc(100%);
  transition: border 0.1s ease;
  box-sizing: border-box;
  margin-bottom: 8px;
  vertical-align: top;
}

.multiselect__input::placeholder {
  color: #35495e;
}

.multiselect__tag ~ .multiselect__input,
.multiselect__tag ~ .multiselect__single {
  width: auto;
}

.multiselect__input:hover,
.multiselect__single:hover {
  border-color: #cfcfcf;
}

.multiselect__input:focus,
.multiselect__single:focus {
  border-color: #a8a8a8;
  outline: none;
}

.multiselect__single {
  padding-left: 5px;
  margin-bottom: 8px;
  background: #0ff;
}

.multiselect__tags-wrap {
  display: inline;
}

.multiselect__tags {
  display: block;
  padding: 0px 40px 0 0px;
  border-radius: 0px;
  border: 1px solid #e8e8e8;
  background: #fff;
  font-size: 14px;
  background: #f50;
}

.multiselect__tag {
  position: relative;
  display: inline-block;
  padding: 4px 26px 4px 10px;
  border-radius: 0px;
  margin-right: 10px;
  color: #fff;
  line-height: 1;
  background: #777;
  margin-bottom: 5px;
  white-space: nowrap;
  overflow: hidden;
  max-width: 25%;
  text-overflow: ellipsis;
}

.multiselect__tag-icon {
  cursor: pointer;
  margin-left: 7px;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  font-weight: 700;
  font-style: initial;
  width: 22px;
  text-align: center;
  line-height: 22px;
  transition: all 0.2s ease;
  border-radius: 5px;
}

.multiselect__tag-icon::after {
  content: "×";
  color: #266d4d;
  font-size: 1rem;
}

/* // Remove these lines to avoid green closing button
  //.multiselect__tag-icon:focus,
  //.multiselect__tag-icon:hover {
  //  background: #369a6e;
  //} */

.multiselect__tag-icon:focus::after,
.multiselect__tag-icon:hover::after {
  color: #266d4d;
}

.multiselect__current {
  line-height: 16px;
  min-height: 40px;
  box-sizing: border-box;
  display: block;
  overflow: hidden;
  padding: 8px 12px 0;
  padding-right: 30px;
  white-space: nowrap;
  margin: 0;
  text-decoration: none;
  border-radius: 5px;
  border: 1px solid #e8e8e8;
  cursor: pointer;
}

.multiselect__select {
  line-height: 16px;
  display: block;
  position: absolute;
  box-sizing: border-box;
  width: 40px;
  height: 38px;
  right: 1px;
  top: 1px;
  padding: 4px 8px;
  margin: 0;
  text-decoration: none;
  text-align: center;
  cursor: pointer;
  transition: transform 0.2s ease;
}

.multiselect__placeholder {
  color: #adadad;
  display: inline-block;
  margin-bottom: 10px;
  padding-top: 2px;
}

.multiselect--active .multiselect__placeholder {
  display: none;
}

.multiselect__content-wrapper {
  position: absolute;
  display: block;
  background: #fff;
  width: 100%;
  max-height: 240px;
  overflow: auto;
  border: 1px solid #e8e8e8;
  border-top: none;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  -webkit-overflow-scrolling: touch;
}

.multiselect__content {
  list-style: none;
  display: inline-block;
  padding: 0;
  margin: 0;
  min-width: 100%;
  vertical-align: top;
}

.multiselect--above .multiselect__content-wrapper {
  bottom: 100%;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom: none;
  border-top: 1px solid #e8e8e8;
}

.multiselect__content::-webkit-scrollbar {
  display: none;
}

.multiselect__element {
  display: block;
}

.multiselect__option {
  display: block;
  padding: 12px;
  min-height: 40px;
  line-height: 16px;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  position: relative;
  cursor: pointer;
  white-space: nowrap;
}

.multiselect__option::after {
  top: 0;
  right: 0;
  position: absolute;
  line-height: 40px;
  padding-right: 12px;
  padding-left: 20px;
  font-size: 13px;
}

.multiselect__option--highlight {
  background: #b2c902;
  outline: none;
  color: white;
}

.multiselect__option--highlight::after {
  content: attr(data-select);
  background: #b2c902;
  color: white;
}

.multiselect__option--selected {
  background: #f3f3f3;
  color: #35495e;
  font-weight: bold;
}

.multiselect__option--selected::after {
  content: attr(data-selected);
  color: silver;
}

.multiselect__option--selected.multiselect__option--highlight {
  background: #ff6a6a;
  color: #fff;
}

.multiselect__option--selected.multiselect__option--highlight::after {
  background: #ff6a6a;
  content: attr(data-deselect);
  color: #fff;
}

.multiselect--disabled .multiselect__current,
.multiselect--disabled .multiselect__select {
  background: #ededed;
  color: #a6a6a6;
}

.multiselect__option--disabled {
  background: #ededed !important;
  color: #a6a6a6 !important;
  cursor: text;
  pointer-events: none;
}

.multiselect__option--group {
  background: #ededed;
  color: #35495e;
}

.multiselect__option--group.multiselect__option--highlight {
  background: #35495e;
  color: #fff;
}

.multiselect__option--group.multiselect__option--highlight::after {
  background: #35495e;
}

.multiselect__option--disabled.multiselect__option--highlight {
  background: #dedede;
}

.multiselect__option--group-selected.multiselect__option--highlight {
  background: #ff6a6a;
  color: #fff;
}

.multiselect__option--group-selected.multiselect__option--highlight::after {
  background: #ff6a6a;
  content: attr(data-deselect);
  color: #fff;
}

.multiselect-enter-active,
.multiselect-leave-active {
  transition: all 0.3s ease;
}

.multiselect-enter,
.multiselect-leave-active {
  opacity: 0;
}

.multiselect__strong {
  margin-bottom: 8px;
  line-height: 20px;
  display: inline-block;
  vertical-align: top;
}

.multiselect__spinner {
  position: absolute;
  right: 1px;
  top: 1px;
  width: 48px;
  height: 35px;
  background: #fff;
  display: block;
}

.multiselect__spinner::before,
.multiselect__spinner::after {
  position: absolute;
  content: "";
  top: 50%;
  left: 50%;
  margin: -8px 0 0 -8px;
  width: 16px;
  height: 16px;
  border-radius: 100%;
  border-color: #41b883 transparent transparent;
  border-style: solid;
  border-width: 2px;
  box-shadow: 0 0 0 1px transparent;
}

.multiselect__spinner::before {
  animation: spinning 2.4s cubic-bezier(0.41, 0.26, 0.2, 0.62);
  animation-iteration-count: infinite;
}

.multiselect__spinner::after {
  animation: spinning 2.4s cubic-bezier(0.51, 0.09, 0.21, 0.8);
  animation-iteration-count: infinite;
}

.multiselect__loading-enter-active,
.multiselect__loading-leave-active {
  transition: opacity 0.4s ease-in-out;
  opacity: 1;
}

.multiselect__loading-enter,
.multiselect__loading-leave-active {
  opacity: 0;
}

@keyframes spinning {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(2turn);
  }
}
</style>
