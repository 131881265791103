<template>
  <div
    v-if="offlineReady || needRefresh"
    class="absolute top-0 left-0 flex flex-wrap md:flex-nowrap bg-pink-900 text-white text-sm px-6 py-2 justify-between align-middle w-full"
    style="z-index: 10000000"
    role="alert"
  >
    <div class="message mt-1">
      <span v-if="offlineReady"> Die App wurde vollständig geladen </span>
      <span v-else>
        Die App wurde aktualisiert. Bitte klicken Sie auf den Refresh-Button
      </span>
    </div>
    <div class="buttons flex align-middle mt-2 md:mt-0">
      <button
        v-if="needRefresh"
        @click="updateServiceWorker()"
        class="w-full px-4 py-2 text-sm text-white leading-none transition-colors duration-150 bg-pink-900 border border-white rounded sm:w-auto active:bg-pink-600 hover:bg-pink-700 focus:outline-none focus:shadow-outline-purple mr-4"
      >
        Refresh
      </button>
      <button
        @click="close"
        class="w-full px-4 py-2 text-sm text-white leading-none transition-colors duration-150 bg-pink-900 border border-white rounded sm:w-auto active:bg-pink-600 hover:bg-pink-700 focus:outline-none focus:shadow-outline-purple"
      >
        Schliessen
      </button>
    </div>
  </div>
</template>

<script setup>
import { defineComponent } from "vue";
import { useRegisterSW } from "virtual:pwa-register/vue";

const { offlineReady, needRefresh, updateServiceWorker } = useRegisterSW({
  onRegisteredSW(swUrl) {
    console.log(`Service Worker at: ${swUrl}`);
  },
});

// const close = async () => {
//   offlineReady.value = false;
//   needRefresh.value = false;
// };

async function close() {
  offlineReady.value = false;
  needRefresh.value = false;
}
</script>
