<template>
  <ReloadPWA />
  <teleport to="body">
    <vue3-snackbar top right dense group :duration="5000"></vue3-snackbar>
  </teleport>

  <router-view v-if="!loading"></router-view>

  <b-main-loader v-else />
  <b-bugtracker v-if="bugtracker.modal" />
</template>

<script setup>
import ReloadPWA from "./ReloadPWA.vue";
import BMainLoader from "@/components/global/BMainLoader.vue";
import BBugtracker from "@/components/global/modals/BBugtracker.vue";
import { ref, computed, onMounted, watch } from "vue";
import { storeToRefs } from "pinia";
import { useGlobalStore } from "@/stores/global";

const global = useGlobalStore();
const { bugtracker, online } = storeToRefs(global);

// import { useAuthStore } from "@/stores/auth";
// import { useUserDataStore } from "./stores/userdata";
// const auth = useAuthStore();
// import { useUserDataStore } from "@/stores/userdata";
// import { useAuthStore } from "@/stores/auth";
// import { storeToRefs } from "pinia";

// const userData = useUserDataStore();
// const global = useGlobalStore();
// const auth = useAuthStore();

// const { authenticated } = storeToRefs(auth);

// watchEffect(() => {
//   if (authenticated.value) {π
//     preload();
//   }
// });

//FIXME: fsfsds

onMounted(() => {
  online.value = navigator.onLine;
  window.addEventListener("online", updateOnlineStatus);
  window.addEventListener("offline", updateOnlineStatus);
});

function updateOnlineStatus() {
  online.value = navigator.onLine;
}

const loading = computed(() => {
  return global.loadingOptions || global.loadingMeineDaten;
});

function preload() {
  // global.getMainMenu()
  // global.getUserMenu()
  // global.getProfileDokumenteItems()
  // global.getProfileVerwaltungItems()
  // global.getMainMenu();
  // console.log("getMainMenu");
  // global.getUserMenu();
  // console.log("getUserMenu");
  // global.getDoktree();
  // console.log("getDokatree");
  // global.getOrgatree();
  // console.log("getOrgatree");
  // userData.getUserData();
  //   global.options["test"] = {
  //     string: "",
  //     checkbox: 0,
  //     radiobutton: 0,
  //     radiobuttons: 0,
  //     textarea: "",
  //     select: { id: 0, name: "Bitte auswählen" },
  //     input: "",
  //     upload: 0,
  //     number: 0,
  //     uploadstring: 0,
  //     checkbox1: 0,
  //     checkbox2: 0,
  //     checkbox3: 0,
  //   };
  // global.getSettings()
}
// import ReloadPWA from "./ReloadPWA.vue";
// import { useStore } from 'vuex'
// import { useUserDataStore } from '@/stores/userdata'
// const userData = useUserDataStore()
// userData.getUserData()
// import { useOptionsStore } from '@/stores/options'
// const options = useOptionsStore()
// options.getOptions()
// import { useOptionsStore } from '@/stores/options'
// const options = useOptionsStore()
// options.getOptions().then(() => {
// })
</script>

<style lang="scss">
*:focus {
  outline: none;
}

* {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

/* *:focus {
     @apply ring ring-drc-gelb ring-opacity-70;
} */

/* .h-screen {
   height: 100vh;
} */

html {
  font-size: 16px !important;
}

@media screen {
}

@media print {
  body {
    zoom: 75%;
  }
}

/* @page {
    size: 21cm 29.7cm;
    margin: 20mm 5mm 20mm 5mm;

} */
@page {
  size: 21cm 29.7cm;
  margin: 5mm 5mm 5mm 5mm;
}

.petable,
.pe-table-q {
  border-collapse: collapse;
  text-align: center;
  vertical-align: middle;
  width: 100%;
  font-size: 0.875rem;
  line-height: 1.25rem;

  tr {
    min-height: 40px;
  }

  th {
    background-color: #79b51c;
    color: white;
    padding: 10px;
    border: 2px solid white;
    min-height: 40px;
  }

  td {
    background-color: #eee;
    color: black;
    padding: 10px;
    border: 2px solid white;
    min-height: 40px;
  }

  .headline {
    width: 100%;
    text-align: center !important;
    background-color: transparent !important;
    color: black !important;
    border: none !important;
    font-size: 1rem !important;
    padding-top: 40px !important;
  }
}

.petable-l,
.pe-table-h {
  border-collapse: collapse;
  text-align: left;
  vertical-align: middle;
  width: 100%;
  font-size: 0.875rem;
  line-height: 1.25rem;

  tr {
    min-height: 40px;
  }

  th {
    background-color: #79b51c;
    color: white;
    padding: 10px 20px;
    border: 2px solid white;
    width: 250px !important;
    min-height: 40px;
  }

  td {
    background-color: #eee;
    color: black;
    padding: 10px 20px;
    border: 2px solid white;
    min-height: 40px;
  }
  .headline {
    width: 100%;
    text-align: center !important;
    background-color: transparent !important;
    color: black !important;
    border: none !important;
    font-size: 1rem !important;
    padding-top: 40px !important;
  }
}

.pe-table-p-q {
  border-collapse: collapse;
  text-align: center;
  vertical-align: middle;
  font-size: 0.875rem;
  line-height: 1.25rem;

  tr {
    height: 40px;
  }

  th {
    background-color: #79b51c;
    color: white;
    padding: 10px;
    border: 2px solid white;
    min-width: 50px;
    min-height: 40px;
  }

  td {
    background-color: #eee;
    color: black;
    padding: 10px;
    border: 2px solid white;
    min-height: 40px;
  }

  .headline {
    width: 100%;
    text-align: left !important;
    background-color: transparent !important;
    color: black !important;
    border: none !important;
    font-size: 1rem !important;
    padding-top: 40px !important;
  }
}
</style>
